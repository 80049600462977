import React from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { Layout } from './components';
import { routes, routeNames } from './routes';
import { PageNotFound } from './pages';

const App: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path={routeNames.app} element={<Navigate to={routeNames.home} />} />
                    {routes.map((route, index) => (
                        <Route
                            path={route.path}
                            key={'route-key-' + index}
                            element={<route.component />}
                        />
                    ))}
                    <Route path='*' element={<PageNotFound />} />
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;
