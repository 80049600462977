import React from 'react';
import './index.scss';

function Loading(props) {
    return (
        <div className={props.className ? props.className : ''}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: 'rgba(255,255,255,0.25)' }}
            >
                <rect className="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10" />
                <rect
                    className="spinner_9y7u spinner_DF2s"
                    x="1"
                    y="1"
                    rx="1"
                    width="10"
                    height="10"
                />
                <rect
                    className="spinner_9y7u spinner_q27e"
                    x="1"
                    y="1"
                    rx="1"
                    width="10"
                    height="10"
                />
            </svg>
        </div>
    );
}

export default Loading;
