import {
    ClientFactory,
    DefaultProviderUrls,
    WalletClient,
} from '@massalabs/massa-web3';
import { baseAccount_Secret_key } from './config';

const createTestnetClient = async () => {
    const baseAccount = await WalletClient.getAccountFromSecretKey(baseAccount_Secret_key);
    const testnetClient = await ClientFactory.createDefaultClient(
        DefaultProviderUrls.BUILDNET,
        true,
        baseAccount,
    );
    return testnetClient;
};

const getBalance = async (_address: string) => {
    try {
        const web3Client = await massaWeb3.createTestnetClient();
        const rawBalance = await web3Client.wallet().getAccountBalance(_address);
        const balance = rawBalance?.final.toString();
        return balance;
    } catch (error) {
        return undefined;
    }
};

const getOperations = async (_operationIds: Array<string>) => {
    const web3Client = await massaWeb3.createTestnetClient();
    const operations = await web3Client.publicApi().getOperations(_operationIds);
    console.log(operations);
    return operations;
};

const massaWeb3 = {
    createTestnetClient,
    getBalance,
    getOperations,
};

export default massaWeb3;
