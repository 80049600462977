/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useRef, useEffect } from 'react';
import { fromMAS, toMAS } from '@massalabs/massa-web3';
import { useBearby } from '@hicaru/bearby-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import massaWeb3 from '../../utils/web3';
import Modal from 'react-modal';
import { InputToken } from '../../components/common/InputToken';
import { overtime_wallet_address } from '../../utils/config';
import StreamApi from '../../api-clients/streamApi';
import { StreamDataType } from '../../utils/types';
import Loading from '../../components/Loading';
import Switch from '@mui/material/Switch';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface propsTypes {
    isOpen: boolean,
    onCloseModal: () => void;
    onCreated: (stream: StreamDataType) => void;
}

export const CreateStreamModal: FC<propsTypes> = ({ isOpen, onCloseModal, onCreated }) => {
    const { base58, massa, period } = useBearby();
    const startTimeRef = useRef<HTMLInputElement>(null);
    const endTimeRef = useRef<HTMLInputElement>(null);
    const recipientRef = useRef<HTMLInputElement>(null);
    const cancellableRef = useRef<HTMLInputElement>(null);
    const [tokenAmount, setTokenAmount] = useState<number>(0);
    const [tokenBalance, setTokenBalance] = useState<number | undefined>();
    const [curStream, setCurrentStream] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (base58 !== undefined)
            updateBalance();
    }, [base58]);

    useEffect(() => {
        setTokenAmount(0);
        updateBalance();
    }, [isOpen])

    useEffect(() => {
        if (curStream === undefined) return;

        massaWeb3.getOperations([curStream.txId]).then(operations => {
            console.log('getstream result:', operations);
            if (operations.length > 0 && operations[0].is_operation_final) {
                StreamApi.createStream({}, curStream).then(res => {
                    onCreated({ ...curStream, id: res.data.id, status: 0, streamed: 0 })
                    setCurrentStream(undefined);
                })
                setLoading(false);
                onCloseModal();
            }
        });
    }, [period])

    const updateBalance = () => {
        massaWeb3.getBalance(base58 as string).then(balance => {
            console.log(base58, balance);
            if (balance === undefined) {
                setTokenBalance(undefined);
            } else {
                setTokenBalance(toMAS(balance).toNumber());
            }
        });
    };

    const onConfirm = async () => {
        console.log('cal?');
        if (base58 === undefined) return;

        console.log('cal2?');
        const recipient = recipientRef.current?.value;
        const start_time = startTimeRef.current?.value.replace(/[^a-zA-Z0-9/: ]/g, "");
        const end_time = endTimeRef.current?.value.replace(/[^a-zA-Z0-9/: ]/g, "");
        const cancellable = cancellableRef.current?.checked;

        if (recipient === '' || start_time === '' || start_time === undefined || end_time === undefined || end_time === '' || tokenBalance === undefined || tokenAmount <= 0 || tokenAmount > tokenBalance) {
            return;
        }

        const now_time = Date.now();
        const start_date = new Date(start_time).getTime();
        const end_date = new Date(end_time).getTime();

        console.log(now_time, start_date, end_date);
        if (now_time > start_date || start_date > end_date || end_date < now_time) {
            return;
        }

        const txId = await massa.payment(fromMAS(tokenAmount).toString(), overtime_wallet_address);
        const stream = {
            txId: txId,
            sender: base58,
            recipient: recipient,
            amount: tokenAmount,
            start_time: start_time,
            end_time: end_time,
            cancelable: cancellable
        };
        setCurrentStream(stream);
        setLoading(true);
    };

    return (
        <Modal
            isOpen={isOpen}
            // onRequestClose={onCloseModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <div className='create-stream-modal'>
                <div className='modal-title'>Create Stream</div>
                <div className='modal-description mt-2'>Start streaming with a simple one-time deposit</div>

                <div className='mt-4 d-flex flex-column' style={{ rowGap: '15px' }}>
                    <div className='input-info-box'>
                        <span>RECIPIENT ADDRESS</span>
                        <input className='overtime-input' ref={recipientRef} />
                    </div>

                    <div className='input-info-box'>
                        <span>TOKEN AMOUNT</span>
                        <InputToken tokenAmount={tokenAmount} tokenBalance={tokenBalance} onChangeTokenAmount={setTokenAmount} />
                    </div>

                    <div className='input-info-box'>
                        <span>START TIME</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                sx={{
                                    fieldset: {
                                        border: 'none'
                                    },
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'Poppins',
                                        color: 'white',
                                        fontSize: '1rem',
                                        background: '#131A2A',
                                        borderRadius: '16px',
                                    },
                                }}
                                inputRef={startTimeRef} />
                        </LocalizationProvider>
                    </div>

                    <div className='input-info-box'>
                        <span>END TIME</span>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDateTimePicker
                                sx={{
                                    fieldset: {
                                        border: 'none'
                                    },
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'Poppins',
                                        color: 'white',
                                        fontSize: '1rem',
                                        background: '#131A2A',
                                        borderRadius: '16px',
                                    },
                                }}
                                inputRef={endTimeRef} />
                        </LocalizationProvider>
                    </div>

                    <div className='d-flex align-items-center'>
                        <span className='me-1 text-gray' style={{ fontSize: '13px' }}>Cancellable</span>
                        <Switch inputRef={cancellableRef} />
                    </div>
                </div>

                <div className='d-flex justify-content-end gap-2'>
                    {
                        loading ? <Loading className='me-3 mt-2' /> : (
                            <>
                                <div className='but modal-but' onClick={onConfirm}>Confirm</div>
                                <div className='but modal-but' onClick={onCloseModal}>Cancel</div>
                            </>
                        )
                    }

                </div>
            </div>
        </Modal>
    )
}