import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import './assets/sass/customized.scss';
import { BearbyProvider } from '@hicaru/bearby-react';

const container: HTMLElement | null = document.getElementById('root');
const root = ReactDOM.createRoot(container as Element);
root.render(
    <StyledEngineProvider injectFirst>
        <BearbyProvider>
            <App />
        </BearbyProvider>
    </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
