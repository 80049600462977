import { FC, useState, useEffect } from 'react';
import { useBearby } from '@hicaru/bearby-react';
import { fromMAS, toMAS } from '@massalabs/massa-web3';
import { Modal, Form, Button, Input, Slider, InputNumber } from 'antd';
import { overtime_wallet_address } from '../../utils/config';
import massaWeb3 from '../../utils/web3';

import './CreateMultiSigModal.scss';
import MultiSigApi from '../../api-clients/multiSigApi';


interface propsTypes {
  isOpen: boolean,
  onCloseModal: () => void;
}

export const CreateMultiSigModal: FC<propsTypes> = ({ isOpen, onCloseModal }) => {
  const { base58, massa } = useBearby();
  const [tokenAmount, setTokenAmount] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [participantCount, setParticipantCount] = useState(3);
  const [agreeCount, setAgreeCount] = useState(2);

  useEffect(() => {
    if (base58 !== undefined)
      updateBalance();
  }, [base58]);

  useEffect(() => {
    setTokenAmount(0);
    updateBalance();
  }, [isOpen]);

  const updateBalance = () => {
    massaWeb3.getBalance(base58 as string).then(balance => {
      if (balance === undefined) {
        setTokenBalance(undefined);
      } else {
        setTokenBalance(toMAS(balance).toNumber());
      }
    });
  };

  const onParticipantChange = (value: number) => {
    setParticipantCount(value);
  }

  const renderEmailList = (count: number | number[]) => {
    const renderCount: number = typeof (count) === 'number' ? count : count[0];
    const element: Array<JSX.Element> = [];
    for (let i = 0; i < renderCount; i++) {
      element.push(
        <Form.Item
          label={`OwnerAddress-${i + 1}`}
          key={`email-${i}`}
          name={`address-${i + 1}`}
          rules={[{ required: true, message: 'Please input owner address' }]}
        >
          <Input />
        </Form.Item>
      );
    }

    return element;
  }

  const onFinish = async (value: any) => {
    console.log('values', value);
    // const txId = await massa.payment(fromMAS(value.tokenAmount).toString(), overtime_wallet_address);
    const txId = '52341';

    const ownerAddresses = [];
    for (let i = 0; i < value.participantCount; i++) {
      console.log('not here?', value[`address-${i + 1}`]);
      ownerAddresses.push(value[`address-${i + 1}`]);
    }

    console.log('addresses:', ownerAddresses);

    const multiSigInfo = {
      txId: txId,
      sender: base58,
      recipient: value.receiveAddress,
      amount: value.tokenAmount,
      confirmCount: value.confirmCount,
      ownerCount: value.participantCount,
      ownerAddresses,
    };

    setLoading(true);
    MultiSigApi.createMutliSig({}, multiSigInfo).then(res => {
      console.log('res info:', res);
      setLoading(false);
    }).catch(err => {
      console.log('Set Mutli-Sig Error:', err);
      setLoading(false);
    });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCloseModal}
      className="multisig-modal"
      footer={<div className='d-flex justify-content-end gap-2'>
        {
          <div>
          </div>
        }
      </div>}
    >
      <h4>Create MultiSig</h4>
      <p className='modal-description mt-2'>Start MultiSig deposit</p>
      <Form
        onFinish={onFinish}
        initialValues={{
          'remember': true,
          'participantCount': participantCount,
          'confirmCount': 2,
          'tokenAmount': 1,
        }}
      >
        <div className='mt-4 d-flex flex-column' style={{ rowGap: '15px' }}>
          <Form.Item
            label="RECIPIENT ADDRESS"
            name="receiveAddress"
            rules={[{ required: true, message: 'Please input recipient address!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="PARTICIPANT COUNT"
            name="participantCount"
          >
            <Slider
              min={3}
              max={10}
              value={participantCount}
              onChange={onParticipantChange}
            />
          </Form.Item>

          {renderEmailList(participantCount)}

          <Form.Item
            name="confirmCount"
            label="Any transaction requires the confirmation of:"
          >
            <InputNumber
              size="large"
              max={participantCount}
              min={2}
              value={agreeCount}
              onChange={(value) => setAgreeCount(value === null ? 0 : value)}
            />
          </Form.Item>

          <Form.Item
            label={`TOKEN AMOUNT (Token Balance is: ${tokenBalance})`}
            name="tokenAmount"
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button loading={loading} htmlType='submit'>Confirm</Button>
              <Button onClick={onCloseModal}>Cancel</Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}