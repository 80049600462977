import React from 'react';
import Hover3D from '../components/Hover3D';
import { GiTimeTrap } from 'react-icons/gi';
import { MdManageHistory } from 'react-icons/md';
import { BiMoneyWithdraw } from 'react-icons/bi';
import massaImg from '../assets/img/massa.png';

export const Home = () => {
    return (
        <div className='container' style={{ marginTop: '60px' }}>
            <div className='d-flex justify-content-center flex-column align-items-center'>
                <h1 className='home-title'>The protocol for real-time payments</h1>
                <div className='page-description' style={{ fontSize: '1.2rem' }}>
                    Why wait one month to get your salary when you can get paid each second with OverTime?
                </div>

                <div className='mt-4 text-gray' style={{ fontSize: '1.25rem' }}>Live on <img src={massaImg} alt={'massa'} width={'150px'} /></div>

                <div className='but start-but' style={{ margin: '50px 0px' }}>
                    Get Started
                </div>
            </div>

            <div className='row mt-4 gy-3'>
                <div className='col-sm-4'>
                    <Hover3D>
                        <div className='title'>Start Streaming <GiTimeTrap /></div>
                        <span>{"With a simple, one-time deposit, you can pay money, do token vesting, and many other things in a descentralized way."}</span>
                    </Hover3D>
                </div>

                <div className='col-sm-4'>
                    <Hover3D>
                        <div className='title'>Manage your streams <MdManageHistory /></div>
                        <span>{"You can see and manage all of your incoming or created streams, check the status and quickly get an overview"}</span>
                    </Hover3D>
                </div>

                <div className='col-sm-4'>
                    <Hover3D>
                        <div className='title'>Real-time claim <BiMoneyWithdraw /></div>
                        <span>{"Payments shouldn’t be static. With OverTime your tokens unlock each second and you can withdraw them when ever you want."}</span>
                    </Hover3D>
                </div>
            </div>
        </div>
    )
}