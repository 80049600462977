import React from 'react';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { IoMenu } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../../../routes';
import clsx from 'clsx';
import { useBearby } from '@hicaru/bearby-react';
import { getReductionAddress } from '../../../utils/address';
import { PngIcon } from '../../../assets';

export const Navbar = () => {
	const location = useLocation();
	const { wallet, base58 } = useBearby();

	const onConnect = async () => {
		await wallet.connect();
	};

	const onDisconnect = async () => {
		await wallet.diconnect();
	}

	return (
		<div>
			<BsNavbar className='header' expand='lg' collapseOnSelect>
				<div className='container-fluid container'>
					<Link to={routeNames.home} className='header-title'>
						<img src={PngIcon.OverTimeLogoDark} alt="logo" style={{ height: '64px' }} />
					</Link>

					<BsNavbar.Toggle aria-controls='responsive-navbar-nav'>
						<IoMenu />
					</BsNavbar.Toggle >

					<BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap' style={{ columnGap: '0.5rem' }}>
						<Nav className='navbar_but_box'>
							<Link to={routeNames.home}
								className={clsx(
									{
										'navbar_but': true,
										'navbar_but_focus': routeNames.home === location.pathname
									})}
							>
								Home
							</Link>
						</Nav>

						<Nav className='navbar_but_box'>
							<Link
								to={routeNames.dashboard}
								className={clsx(
									{
										'navbar_but': true,
										'navbar_but_focus': routeNames.dashboard === location.pathname
									})}
							>
								Dashboard
							</Link>
						</Nav>

						<Nav className='navbar_but_box'>
							<Link
								to={routeNames.multiSig}
								className={clsx(
									{
										'navbar_but': true,
										'navbar_but_focus': routeNames.multiSig === location.pathname
									})}
							>
								Multi Sign Wallet
							</Link>
						</Nav>

						{
							base58 ? (
								<NavItem>
									<div className="but connect" onClick={onDisconnect}>
										{getReductionAddress(base58)}
									</div>
								</NavItem>
							) : (
								<NavItem>
									<div className="but connect" onClick={onConnect}>
										Connect
									</div>
								</NavItem>
							)
						}
					</BsNavbar.Collapse>
				</div>
			</BsNavbar >
		</div >
	);
};
