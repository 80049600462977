import React, { useState, FC } from 'react';
import { useBearby } from '@hicaru/bearby-react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Box, Button } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

import { CreateMultiSigModal } from './CreateMutliSigModal';
import MultiSigPending from './Tabs/MultiSigPending';
import MultiSigAccepted from './Tabs/MultiSigAccepted';
import MultiSigRejected from './Tabs/MultiSigRejected';
import './index.scss';

export const MultiSignWallet: FC<any> = () => {
  const { base58 } = useBearby();
  const [multiSigOpen, setMultiSigOpen] = useState(false);
  const [tabValue, setTabValue] = useState('pending');

  function onOpenMultiSigModal() {
    setMultiSigOpen(true);
  }

  function opCloseMultiSigModal() {
    setMultiSigOpen(false);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <div className="multi-sign-wallet">
      <div className="header">
        <h3>Manage Multi-Sign Wallet</h3>
        {base58 &&
          <Button
            variant="contained"
            onClick={onOpenMultiSigModal}
            startIcon={<AddOutlined />}
          >
            Create Multi Sig Transaction
          </Button>
        }
      </div>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Multi-Sig Transactions List">
            <Tab label="Pending" value="pending" style={{ color: '#F7CAC9' }} />
            <Tab label="Accepted" value="accepted" style={{ color: '#92A8D1' }} />
            <Tab label="Rejected" value="rejected" style={{ color: '#002341' }} />
          </TabList>
        </Box>
        <TabPanel value="pending">
          <MultiSigPending />
        </TabPanel>
        <TabPanel value="accepted">
          <MultiSigAccepted />
        </TabPanel>
        <TabPanel value="rejected">
          <MultiSigRejected />
        </TabPanel>
      </TabContext>
      {base58 && (<CreateMultiSigModal isOpen={multiSigOpen} onCloseModal={opCloseMultiSigModal} />)}
    </div >
  );
};