import React, { FC, useEffect, useState } from 'react';
import { useBearby } from '@hicaru/bearby-react';
import { Row, Card, Skeleton } from 'antd';

import MultiSigApi from '../../../api-clients/multiSigApi';

interface propTypes {

}

const MultiSigPending: FC<propTypes> = () => {
  const { base58 } = useBearby();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (base58 === undefined) {
      setLoading(false);
      return;
    }

    MultiSigApi.getMutliSigPendingTransaction({
      account_addr: base58,
    }).then((res: any) => {
      console.log('asdf:', res);
      // setData(res.data);
      setLoading(false);
      // @Todo shows all the multi-sig transactions
    }).catch(err => {
      console.log('err:', err);
      setLoading(false);
    });
  }, [base58]);

  return (
    <Row className="multi-sig-pending">
      <h5>Multi Sig Pending</h5>
      {
        loading && <Skeleton />
      }
      {
        data.map(item => (
          <Card>
            item.id
          </Card>
        ))
      }
    </Row>
  );
};

export default MultiSigPending;