import React, { FC } from 'react';

interface propTypes {

}

const MultiSigRejected: FC<propTypes> = () => {
  return (
    <div className="multi-sig-pending">
      Multi Sig Pending
    </div>
  );
};

export default MultiSigRejected;