import { instance } from './index';

export const multiSigUrl = '/multi-sig';

export default class MultiSigApi {
  static createMutliSig = (query: any, body: any) => {
    return instance.post(multiSigUrl, body, { params: query });
  };

  static getMutliSigPendingTransaction = (query: any) => {
    return instance.get(`${multiSigUrl}/pending`, { params: query });
  }

  static getMutliSigAcceptedTransaction = (query: any) => {
    return instance.get(`${multiSigUrl}/accepted`, { params: query });
  }

  static getMutliSigRejectedTransaction = (query: any) => {
    return instance.get(`${multiSigUrl}/rejected`, { params: query });
  }

  static cancelTransaction = (query: any) => {
    return instance.delete(multiSigUrl, { params: query });
  }
}