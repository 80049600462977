import React, { useEffect, useState } from 'react';
import { StreamRow } from './StreamRow';
import { CreateStreamModal } from './CreateStreamModal';
import { useBearby } from '@hicaru/bearby-react';
import StreamApi from '../../api-clients/streamApi';
import { StreamDataType } from '../../utils/types';
import Loading from '../../components/Loading';

export const Dashboard = () => {
    const { base58 } = useBearby();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [streamList, setStreamList] = useState<Array<StreamDataType>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (base58 === undefined) {
            setLoading(false);
            return;
        }
        StreamApi.getStreams({ account_addr: base58 })
            .then(res => {
                console.log('data:', res.data);
                setStreamList(res.data);
                setLoading(false);
            }).catch(err => {
                console.log('get stream error:', err);
                setLoading(false);
            });
    }, [base58]);

    function onOpenModal() {
        setModalIsOpen(true);
    }

    function onCloseModal() {
        setModalIsOpen(false);
    }

    const onCanceledStream = (stream: StreamDataType) => {
        const t_streamList = [...streamList].map(row => {
            if (row.id === stream.id) return stream;
            return row;
        });
        setStreamList(t_streamList);
    }

    const onStreamClaimed = (stream: StreamDataType) => {
        setStreamList(streamList.map(item => {
            if (item.id === stream.id) {
                return {
                    ...item,
                    status: 2,
                }
            }

            return item;
        }));
    }

    const onCreatedStream = (stream: StreamDataType) => {
        setStreamList([stream, ...streamList]);
    }

    return (
        <>
            <div className='container app-container' style={{ marginTop: '2.5rem' }}>
                <div className='d-flex flex-column text-center gap-2'>
                    <span className='page-title'>Dashboard</span>
                    <span className='page-description'>
                        We know how important is to get paid on time. With OverTime, on time means every second.
                    </span>
                </div>

                <div className='d-flex gap-2 justify-content-center mt-4'>
                    <div className='state-badge state-receive'>Receive</div>
                    <div className='state-badge state-send'>Send</div>
                </div>

                {
                    loading ? (
                        <div className='d-flex justify-content-center' style={{ marginTop: '60px' }}>
                            <Loading />
                        </div>
                    ) : (
                        <div className='row gap-3 my-4 p-3'>
                            {streamList.map((row, index) => <StreamRow onClaimed={onStreamClaimed} data={row} key={`streamRow${index}`} onCanceled={onCanceledStream} />)}
                        </div>
                    )
                }

                {base58 && (
                    <div className='create-stream-but' onClick={onOpenModal}></div>
                )}

            </div>

            {base58 && (<CreateStreamModal isOpen={modalIsOpen} onCloseModal={onCloseModal} onCreated={onCreatedStream} />)}
        </>
    )
}