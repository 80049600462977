import OverTimeFavIconLight from './overtime-favicon-light.png';
import OverTimeFavIconDark from './overtime-favicon-dark.png';
import OverTimeIconLight from './overtime-icon-light.png';
import OverTimeLogoDark from './overtime-logo-dark.png';
import OverTimeLogoLight from './overtime-logo-light.png';
import OverTimeIconDark from './overtime-icon-dark.png';
import KeplrIcon from './Keplr-Icon.png';
import KeplrWalletImage from './KeplrWalletImage.png';
import CosmostationIcon from './Cosmostation-Icon.png';
import CosmostationImage from './CosmostationImage.png';


export const PngIcon = {
    OverTimeFavIconLight,
    OverTimeFavIconDark,
    OverTimeIconDark,
    OverTimeIconLight,
    OverTimeLogoDark,
    OverTimeLogoLight,

    KeplrIcon,
    KeplrWalletImage,

    CosmostationIcon,
    CosmostationImage,
};