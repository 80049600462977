import React, { FC } from 'react';

interface propTypes {

}

const MultiSigAccepted: FC<propTypes> = ({ }) => {
  return (
    <div className="multi-sig-processed">
      Multi Sig Processed Panel
    </div>
  );
};

export default MultiSigAccepted;