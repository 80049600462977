import { FC } from 'react';
import clsx from 'clsx';
import massa_logo from '../../assets/img/massa-logo.png';

interface propsInterface {
  className?: string;
  tokenAmount: number | undefined;
  tokenBalance: number | undefined;
  onChangeTokenAmount: (e: number) => void;
  token?: any;
}

export const InputToken: FC<propsInterface> = ({
  className,
  tokenAmount,
  tokenBalance,
  onChangeTokenAmount,
}) => {
  return (
    <>
      <div className={clsx('token_input_box', className)}>
        <div className="d-flex align-items-center">
          <input
            className="token_input"
            type="number"
            placeholder="0"
            value={tokenAmount}
            onChange={(e) => onChangeTokenAmount(Number(e.target.value))}
          />
          <button className="selected-token-but" >
            <img src={massa_logo} alt="logo" width="30px" />
            XMA
          </button>
        </div>

        <div className="d-flex justify-content-end mt-1">
          <span style={{ color: '#98A1C0', marginRight: '16px' }}>
            Balance: {tokenBalance !== undefined ? tokenBalance.toFixed(2) : '-'}
          </span>
        </div>
      </div>
    </>
  );
};
