import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const PageNotFound = () => {
  const { pathname } = useLocation();

  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <span className='h4 empty-heading mt-3'>Page not found</span>
        <span className='empty-details'>{pathname}</span>
      </div>
    </div>
  );
};
