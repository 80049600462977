import { dAppName } from './config';
import { withPageTitle } from './components/PageTitle';
import { Home, Dashboard, MultiSignWallet } from './pages';

export const routeNames = {
    app: '/',
    home: '/home',
    dashboard: '/dashboard',
    multiSig: '/multi-sig',
};

export const routes: any[] = [
    {
        path: routeNames.home,
        title: 'Home',
        component: Home,
        authenticatedRoute: false,
    },
    {
        path: routeNames.dashboard,
        title: 'Dashboard',
        component: Dashboard,
        authenticatedRoute: false,
    },
    {
        path: routeNames.multiSig,
        title: 'Multi-Sign Wallet',
        component: MultiSignWallet,
        authenticatedRoute: false,
    }
];

export const mappedRoutes = routes.map((route) => {
    const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;
    const requiresAuth = Boolean(route.authenticatedRoute);
    const wrappedComponent = withPageTitle(title, route.component);

    return {
        path: route.path,
        component: wrappedComponent,
        authenticatedRoute: requiresAuth,
    };
});
