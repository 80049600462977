import { instance } from './index';

export const create_stream = '/stream/create';
export const get_streams = '/stream/getList';
export const cancel_stream = '/stream/cancel';
export const claim_stream = '/stream/claim';

class StreamApi {
    static createStream = (query: any, body: any) => {
        return instance.post(create_stream, body, { params: query });
    };

    static getStreams = (query: any) => {
        return instance.get(get_streams, { params: query });
    };

    static cancelStream = (query: any, body: any) => {
        return instance.post(cancel_stream, body, { params: query });
    };

    static claimStream = (query: any, body: any) => {
        return instance.post(claim_stream, body, { params: query });
    };
}

export default StreamApi;
