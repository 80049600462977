/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { Snackbar, Alert } from '@mui/material';
import { Collapse } from '@mui/material';
import moment from 'moment';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { StreamDataType } from '../../utils/types';
import { getReductionAddress } from '../../utils/address';
import { getSecondsBetweenTwoDates, getDaysBetweenTwoDates } from '../../utils/date';
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import { useBearby } from '@hicaru/bearby-react';
import StreamApi from '../../api-clients/streamApi';
interface propsTypes {
    data: StreamDataType;
    onClaimed: (stream: StreamDataType) => void,
    onCanceled: (stream: StreamDataType) => void,
}

export const StreamRow: FC<propsTypes> = ({ data, onClaimed, onCanceled }) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [streamedAmount, setStreamedAmount] = useState<number>(0);
    const [claimLoading, setClaimLoading] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [claimState, setClaimState] = useState(false);
    const startDate = new Date(data.start_time);
    const endDate = new Date(data.end_time);
    const timePassed = getDaysBetweenTwoDates(startDate, new Date());
    const timeLeft = getDaysBetweenTwoDates(startDate, endDate) - timePassed;
    const { base58 } = useBearby();
    const [streamType, setStreamType] = useState<number>(0);

    function toggleShowDetailsButton() {
        setShowDetails(!showDetails);
    }

    const getStreamSpeed = () => {
        const totalSecs = getSecondsBetweenTwoDates(new Date(data.start_time), new Date(data.end_time));
        return (data.amount / totalSecs).toPrecision(4);
    }
    const streamSpeed = getStreamSpeed();

    const parseNumber = (value: number): string => {
        if (value % 1 !== 0) {
            return value.toPrecision(6);
        } else {
            return Math.round(value).toString();
        }
    }

    useEffect(() => {
        if (data.sender === base58) {
            setStreamType(1);
        } else {
            setStreamType(0);
        }

        if (data.status === 1) {
            const interval = setInterval(() => {
                const nowDate = new Date();
                const totalSecs = getSecondsBetweenTwoDates(new Date(data.start_time), new Date(data.end_time));
                const passedSecs = getSecondsBetweenTwoDates(new Date(data.start_time), nowDate);
                if (passedSecs >= totalSecs) {
                    setStreamedAmount(data.amount);
                    clearInterval(interval);
                } else {
                    setStreamedAmount(data.amount * passedSecs / totalSecs);
                }
            }, 1000);
        } else {
            if (data.streamed)
                setStreamedAmount(data.streamed);
            else
                setStreamedAmount(0);
        }
    }, []);

    const onCancelStream = () => {
        StreamApi.cancelStream({}, { streamId: data.id }).then(res => {
            onCanceled(res.data);
        });
    };

    const onClaimStream = () => {
        setClaimLoading(true);
        StreamApi.claimStream({}, { streamId: data.id }).then(() => {
            onClaimed(data);
            setClaimState(true);
        }).catch(err => {
            setClaimState(false);
        }).finally(() => {
            setClaimLoading(false);
            setOpenSnack(true);
        });
    }

    return (
        <div className='card' style={{ borderLeft: `5px solid ${streamType === 0 ? "#76D191" : "#FD766B"}` }}>
            <div className='row gy-2'>
                <div className='col-sm-2 row-info'>
                    <span>Address</span>
                    <div className='d-flex gap-2'>
                        <span style={{ color: `${streamType === 0 ? "#76D191" : "#FD766B"}` }}>{streamType === 0 ? <FiArrowDownRight /> : <FiArrowUpRight />}</span>
                        <span>{streamType === 0 ? (`${getReductionAddress(data.sender)}`) : (`${getReductionAddress(data.recipient)}`)}</span>
                    </div>

                </div>
                <div className='col-sm-2 row-info'>
                    <span>Value</span>
                    <div className='d-flex align-items-center gap-2'>
                        <span style={{ fontSize: '16px', color: 'white' }}>{data.amount + " XMA"}</span>
                        <div className='badge'>{streamType === 0 ? "IN" : "OUT"}</div>
                    </div>
                </div>
                <div className='col-sm-3 row-info' style={{ rowGap: '12px' }}>
                    <span>{`Progress (${Math.ceil(streamedAmount * 100 / data.amount)}%)`}</span>
                    <div style={{ width: '180px' }}>
                        <ProgressBar className='stream-progressbar' now={Math.ceil(streamedAmount * 100 / data.amount)} />
                    </div>
                </div>
                <div className='col-sm-2 row-info'>
                    <span>Start Date</span>
                    <span>{moment(new Date(data.start_time)).format("MM/DD/YYYY hh:mm A")}</span>
                </div>
                <div className='col-sm-2 row-info'>
                    <span>End Date</span>
                    <span>{moment(new Date(data.end_time)).format("MM/DD/YYYY hh:mm A")}</span>
                </div>

                <div className='col-sm-1 row-info align-items-center justify-content-center'>
                    <button className='collapse-button' onClick={toggleShowDetailsButton}>
                        {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                </div>
            </div>

            <Collapse in={showDetails} timeout={1000}>
                <hr className='mt-4' />
                <div className='row mt-2 gy-2'>
                    <div className='col-sm-3 d-flex justify-content-center'>
                        <div style={{ width: '200px' }}>
                            <CircularProgressbarWithChildren
                                value={Math.ceil(streamedAmount * 100 / data.amount)}
                                // text={`${Math.ceil(streamedAmount * 100 / data.amount)}%`}
                                background
                                backgroundPadding={6}
                                styles={buildStyles({
                                    textColor: "#fff",
                                    pathColor: "#4c82fb",
                                    backgroundColor: "#203059",
                                    trailColor: "transparent",
                                })}>
                                <span style={{ fontSize: '20px' }}>{`${Math.ceil(streamedAmount * 100 / data.amount)}%`}</span>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='card p-2'>
                            <div className='info-card-body d-flex flex-column gap-2'>
                                <div className='stream-info'>
                                    <span>Stream</span>
                                    <span>{`${streamSpeed} XMA/s`}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>{streamType === 0 ? 'Received' : 'Sent'}</span>
                                    <span className='text-white'>{`${parseNumber(streamedAmount)} XMA`}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Left</span>
                                    <span className='text-white'>{parseNumber(data.amount - streamedAmount)} XMA</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Time Passed</span>
                                    <span>{data.status === 0 ? timePassed : '-'} Days</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Time Left</span>
                                    <span>{data.status === 0 ? timeLeft : '-'} Days</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-5'>
                        <div className='card p-2'>
                            <div className='info-card-body d-flex flex-column gap-2'>
                                <div className='stream-info'>
                                    <span>{streamType === 0 ? "Sender" : "Recipient"}</span>
                                    <span>{streamType === 0 ? getReductionAddress(data.sender) : getReductionAddress(data.recipient)}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Value</span>
                                    <span className='text-white'>{data.amount + " XMA"}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Start Date</span>
                                    <span>{moment(new Date(data.start_time)).toLocaleString()}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>End Date</span>
                                    <span>{moment(new Date(data.end_time)).toLocaleString()}</span>
                                </div>
                                <div className='stream-info'>
                                    <span>Status</span>
                                    <span>{data.status === 0 ? 'In-Active' : data.status === 1 ? 'Ongoing' : data.status === 2 ? 'Completed' : 'Canceled'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    data.status === 1 && (
                        <div className='d-flex justify-content-end mt-2'>
                            {
                                streamType === 0 ? (
                                    // <div className='but cancel-stream' onClick={onClaimStream}>Claim</div>
                                    <LoadingButton
                                        style={{ fontSize: '16px', borderRadius: '30px' }}
                                        loading={claimLoading}
                                        onClick={onClaimStream}
                                        loadingPosition='center'
                                        loadingIndicator={(<CircularProgress color="primary" size={16} />)}
                                    >Claim</LoadingButton>
                                ) : (
                                    <>
                                        {
                                            data.cancelable && (<div className='but cancel-stream' onClick={onCancelStream}>Cancel Stream</div>)
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }

            </Collapse>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={() => setOpenSnack(false)}>
                {
                    claimState ?
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Successfully Claimed
                        </Alert> : <Alert severity="error" sx={{ width: '100%' }}>
                            Claim Failed
                        </Alert>
                }
            </Snackbar>
        </div>
    )
}